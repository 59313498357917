import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Header from './layout/Header';
import Go2Top from './layout/Go2Top';
import Cookies from './layout/Cookies';

import '../styles/layout.scss';
import Footer from './layout/Footer';

const StyledLayout = styled.div`
  margin: 0 auto;
  padding: 0;
  max-width: 1110px;
  @media only screen and (max-width: 1200px) {
    & {
      max-width: 930px;
    }
  }
  @media only screen and (max-width: 992px) {
    & {
      max-width: 690px;
    }
  }
  @media only screen and (max-width: 768px) {
    & {
      max-width: 510px;
      padding: 0 16px;
    }
  }
`;

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <StyledLayout>
      <Header siteTitle={data.site.siteMetadata.title} path={path} />
      <main>{children}</main>
      <Go2Top />
      <Cookies />
      <Footer siteTitle={data.site.siteMetadata.title} />
    </StyledLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
};

export default Layout;
