import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';

const StyledHeader = styled.header`
  padding-top: 32px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 58px auto 58px;
  grid-gap: 16px;
  @media only screen and (max-width: 576px) {
    & {
      grid-template-columns: 1fr;
    }
  }
  justify-items: center;
  align-items: center;

  a {
    text-decoration: none;
  }

  h1 {
    margin: 0;
  }
`;

const Header = ({ siteTitle, path }) => (
  <StyledHeader>
    <Link to={'/'} aria-label={'Home'}>
      <img src={require('../../images/logo.png')} alt={'logo'} />
    </Link>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    {!['/es/', '/en/'].includes(path) && (
      <Link to={'/'} className={'flamingo'} aria-label={'Back'}>
        <FaChevronLeft size={'32px'} />
      </Link>
    )}
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  path: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
