import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import Social from './Social';
import LanguageSwitcher from './LanguageSwitcher';

const StyledFooter = styled.footer`
  padding: 32px 0 32px;
  display: grid;
  grid-gap: 16px;
  justify-items: center;
  text-align: center;
  p {
    margin: 0;
  }
`;

const Footer = ({ siteTitle }) => (
  <StyledFooter>
    <Social />
    <LanguageSwitcher />
    <p>
      {new Date().getFullYear()} © {siteTitle}.{' '}
      <FormattedMessage id={'All Right Reserved.'} />
      <br />
      <Link to={'/cookies-policy'}>
        <FormattedMessage id={'Cookies Policy'} />
      </Link>
    </p>
  </StyledFooter>
);

export default Footer;
