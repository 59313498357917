import React from 'react';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';

const langMap = {
  en: { text: 'Español', lang: 'es' },
  es: { text: 'English', lang: 'en' },
};

const LanguageSwitcher = () => {
  const intl = useIntl();
  return (
    <a href={'#!'} onClick={() => changeLocale(langMap[intl.locale].lang)}>
      {langMap[intl.locale].text}
    </a>
  );
};

export default LanguageSwitcher;
