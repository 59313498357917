import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const StyledSocial = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  a {
    text-decoration: none;
  }
`;

const Social = () => (
  <StyledSocial>
    <a
      href={'https://www.instagram.com/flamencalente'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      aria-label={'Instagram'}
    >
      <FaInstagram size={'24px'} />
    </a>
    <a
      href={'https://www.facebook.com/flamencalente'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      aria-label={'Facebook'}
    >
      <FaFacebook size={'24px'} />
    </a>
  </StyledSocial>
);

export default Social;
