import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

const StyledButton = styled.div`
  a {
    cursor: pointer;
    background-color: #333;
    border-color: #333;
    color: #f7f7f7;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
`;

const Button = ({ children, to, onClick, target }) => (
  <StyledButton>
    {to ? (
      <Link to={to} target={target} onClick={onClick}>
        {children}
      </Link>
    ) : (
      <a onClick={onClick}>{children}</a>
    )}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_blank', '_self']),
};

Button.defaultProps = {
  target: '_self',
  children: 'Button',
};

export default Button;
